<template>
  <div class="c__banner">
    <div class="c__banner__img-banner">
      <img src="@/assets/images/logo.svg" alt="site logo" />
    </div>
    <div class="c__banner__navlinks">
      <ul>
        <li>Home</li>
        <li class="c__banner__sublinks">About
          <ul class="list">
            <li>About Us</li>
            <li>Facilities</li>
            <li>Contact Us</li>
          </ul>
        </li>
        <li>Services</li>
        <li>Schedule</li>
        <li>Contact</li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: "NavBar"
  }
</script>