<template>
  <home-page></home-page>
</template>

<script>
import Home from "./components/Home.vue";

export default {
  name: "App",
  components: {
    HomePage: Home,
  },
};
</script>
