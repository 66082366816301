/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import specific icons */
import { faBars, faTimes, faLocationDot, faPhone, faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";

import {faFacebookF, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons"

/* add icons to the library */
library.add(
  faBars, 
  faTimes, 
  faLocationDot, 
  faPhone, 
  faFacebookF, 
  faInstagram, 
  faYoutube,
  faCaretRight,
  faCaretDown
  );
