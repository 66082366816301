<template>
  <div class="c__header">
    <div class="c__header__hamburger" @click="toggleMenu">
      <font-awesome-icon icon="fa-solid fa-bars" />
    </div>
    <div 
      class="c__header--menu" 
      :class="{'u__visibility--hide': isVisibleMenu}"
    >
      <ul>
        <li>Home</li>
        <li @click="toggleSubmenu('about')">
          About 
          <font-awesome-icon 
            icon="fa-solid fa-caret-right" 
            :class="{'u__visibility--hide': !menu.about}" 
          />
          <font-awesome-icon 
            icon="fa-solid fa-caret-down"  
            :class="{'u__visibility--hide': menu.about}"
          />
          <ul :class="{'u__visibility--hide': menu.about}">
            <li>About Us</li>
            <li>Facility</li>
            <li>FAQs</li>
          </ul>
        </li>
        <li>Services</li>
        <li>Schedule</li>
        <li>Contact</li>
      </ul>
    </div>
    <div class="c__header--contact-info">
      <span class="address">
        <font-awesome-icon 
          icon="fa-solid fa-location-dot" 
          class="u__color--accent" 
        />
        F1, Pol Coop Estate, Obot Akara Cresent off Akpabuyo street, Kubwa, Abuja, Nigeria
      </span>
      <span class="phone">
        <font-awesome-icon 
          icon="fa-solid fa-phone" 
          class="u__color--accent" 
        />
        (810)101-4257
      </span>
      <div class="c__header--social">
        <span>
          <font-awesome-icon icon="fa-brands fa-facebook-f" />
          Facebook
        </span>
        <span>
          <font-awesome-icon icon="fa-brands fa-instagram" />
          Instagram</span>
        <span>
          <font-awesome-icon icon="fa-brands fa-youtube" />
          YouTube
        </span>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PageHeader',
    data() {
      return {
        isVisibleMenu: true,
        menu: {
          about: true,
        }
      }
    },
    methods: {
      toggleMenu() {
        this.isVisibleMenu = !this.isVisibleMenu;
        for (const key in this.menu) {
          this.menu[key] = true;
        }
      },
      toggleSubmenu(submenu) {
        this.menu[submenu] = !this.menu[submenu];
      } 
    }
  }
</script>