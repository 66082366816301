<template>
  <div class="container">
    <page-header></page-header>
    <div class="p__hero">
      <nav-bar></nav-bar>
      <div class="p__hero--textbox">
        <p>"We are more than just a gym;<br>we are a community dedicated to health, fitness, and wellbeing that works tirelessly to help you achieve the best possible level of health."</p>
      </div>
    </div>
    <main class="p__main">
      <div class="p__main__img">
        <img src="@/assets/images/pexels-andrea-piacquadio-903171.jpg" alt="guy doing pullups">
      </div>
      <div class="p__main--text">
        <p>HBFW offers the most robust and diverse fitness programs for all ages in the Abuja metropolis!</p>
        <p>
          <b class="u__text--i">You just</b><br> 
          <span class="u__color--accent u__text--i">SHOW UP</span><br>
          <span class="u__color--accent u__text--i">&</span><br>
          <span class="u__color--accent u__text--i">HAVE FUN</span>          
        </p>

        <p>
          <b class="u__text--i">We promise you'll</b><br>
          <span class="u__color--accent u__text--i">GET FIT</span><br>
          <span class="u__color--accent u__text--i">&</span><br>
          <span class="u__color--accent u__text--i">BE HAPPY</span>
        </p>
        <p>You will actually ENJOY fitness, we guarantee it.</p>
      </div>
    </main>
    <section class="p__section">
      <div class="card">
        <h2>PERSONAL TRAINING</h2>
        <div class="p__main__img">
          <img src="@/assets/images/personal_training.jpg" alt="personal training">
        </div>
      </div>
      <div class="card">
        <h2>GROUP CLASSES</h2>
        <div class="p__main__img">
          <img src="@/assets/images/group_classes.jpg" alt="group classes">
        </div>
      </div>
    </section>
    <footer class="p__footer">
      <div class="p__footer--logo">
        <img src="@/assets/images/site_logo.svg" alt="site logo">
      </div>
      <div class="p__footer--social">
        <span>
          <font-awesome-icon icon="fa-brands fa-facebook-f" class="fa"/>
          Facebook
        </span>
        <span>
          <font-awesome-icon icon="fa-brands fa-instagram" class="fa"/>
          Instagram</span>
        <span>
          <font-awesome-icon icon="fa-brands fa-youtube"  class="fa"/>
          YouTube
        </span>
        <div>Copyright &copy; 2022. All Rights Reserved.</div>
      </div>
      <div class="p__footer--homeplus">
        <div>
          <img src="@/assets/images/cropped-hpms-logo-pro.png" alt="Home plus logo" >
        </div>
        Health, Beauty, Fitness and Wellness is proudly brought to you by Home Plus Medicare Services
      </div>

    </footer>
  </div>
</template>
<script>
  import PageHeader from "@/components/Header";
  import NavBar from "@/components/Nav";
  export default {
    name: "HomePage",
    components: {PageHeader, NavBar},
    data() {
      return {
        showMenu: true,
        email: '',
      };
    },
    methods: {
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
    },
    // data() {
    //   return {
    //     landingSlogan: "Welcome the Health Beauty Fitness and Wellness website!",
    //   };
    // },
  };
</script>
